.menu {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 60px;
  padding: 0 3rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: 1s;

  .menu-content {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;

    .logo {
      display: flex;
      color: #eee;

      span {
        font-family: "Alex Brush", cursive;
        font-size: 3em;
        font-weight: bolder;
        user-select: none;

        &:first-of-type {
          margin-top: -5px;
        }

        &:nth-of-type(2) {
          font-size: 3em;
          margin: 10px 0 0 0;
        }

        &:last-of-type {
          margin-top: 10px;
        }
      }
    }

    .toggle {
      color: #eee;
      margin-left: auto;
      transition: 0.3s;
      cursor: pointer;
      z-index: 99;

      &.toggle--active {
        div:first-of-type {
          transform: translate(0, 8px) rotate(-45deg);
        }

        div:nth-of-type(2) {
          opacity: 0;
        }

        div:last-of-type {
          transform: translate(0, -10px) rotate(45deg);
        }
      }

      div {
        width: 2.5rem;
        height: 3px;
        background-color: #eee;
        margin: 6px 0;
        transition: 0.3s;
      }

      &:hover {
        div {
          background-color: #70a076;
        }
      }
    }
  }

  &.menu--scrolled {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .menu-content {
      .logo {
        transition: 0.3s;
        color: #1a1a1a;
      }

      .toggle {
        div {
          background-color: #70a076;
        }

        &.toggle--active {
          div {
            background-color: #eee;
          }
        }
      }

      nav {
        a {
          color: #1a1a1a;

          &:hover {
            color: #70a076;
          }

          &.lang {
            background-color: #70a076;
            color: white;

            &:hover {
              color: #1a1a1a;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .menu {
    display: flex;
    justify-content: center;

    .menu-content {
      max-width: 1200px;

      .toggle {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .menu {
    &.menu--scrolled {
      .menu-content {
        nav {
          a {
            color: white;
          }
        }
      }
    }
  }
}
