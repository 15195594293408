.story-timeline {
  .timeline-heading {
    font-family: "Alex Brush", cursive;
    font-size: 3em;
    margin: 2em 0 0.5em 0;
    text-align: center;
    margin-bottom: 6rem;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 2em;

    .milestone {
      display: grid;
      grid-template-columns: 0.2fr 0.8fr;
      grid-template-rows: auto auto;
      max-width: 800px;

      .line {
        grid-row: 1 / span 2;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          height: 100%;
          width: 4px;
          background: #f7f7f7;
        }

        &.top:before {
          top: -30px;
          height: calc(100% + 30px);
        }

        &.top:after {
          content: "";
          position: absolute;
          top: -30px;
          left: 50%;
          width: 10px;
          height: 10px;
          background: #70a076;
          border: 5px solid #f7f7f7;
          border-radius: 100%;
          transform: translateX(-45%);
        }

        &.bottom:before {
          bottom: -30px;
          height: calc(100% + 30px);
        }

        &.bottom:after {
          content: "";
          position: absolute;
          bottom: -30px;
          left: 50%;
          width: 10px;
          height: 10px;
          background: #70a076;
          border: 5px solid #f7f7f7;
          border-radius: 100%;
          transform: translateX(-45%);
        }

        .icon-wrapper {
          position: absolute;
          top: 10%;
          left: 50%;
          width: 40px;
          height: 40px;
          background: #f7f7f7;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          transform: translateX(-45%);
        }

        .icon {
          width: 15px;
          height: 15px;

          filter: invert(59%) sepia(21%) saturate(507%) hue-rotate(76deg)
            brightness(94%) contrast(95%);
        }
      }

      .image {
        width: 100%;
        padding: 3em 0;
        max-width: 400px;
      }

      .content {
        font-family: "Poppins", sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          font-family: "Alex Brush", cursive;
          font-size: 2.5em;
        }

        .date {
          color: #70a076;
          margin: 0.5em 0 1em 0;
          font-size: 1.2em;
        }

        .desc {
          margin-bottom: 1em;
          color: #808080;
          font-size: 1.4em;
        }
      }
    }
  }
}

@media only screen and (min-width: 640px) {
  .story-timeline {
    .timeline-heading {
      font-size: 5em;
    }
  }
}

@media only screen and (min-width: 740px) {
  .story-timeline {
    .timeline {
      padding-left: 2em;

      .milestone {
        display: grid;
        grid-template-columns: 1fr 0.5fr 1fr;
        grid-template-rows: auto;
        max-width: 1000px;

        .line {
          grid-column: 2 / span 1;

          .icon-wrapper {
            top: 40%;
            width: 50px;
            height: 50px;
          }

          .icon {
            width: 20px;
            height: 20px;

            &.large {
              width: 23px;
              height: 23px;
            }
          }
        }

        .image.reverse {
          grid-column: 3 / span 1;
        }

        .content.reverse {
          text-align: right;
          grid-column: 1 / span 1;
          grid-row: 1 / span 1;
        }

        .content {
          .title {
            font-size: 3.5em;
          }

          .date {
            font-size: 1.4em;
          }

          .desc {
            font-size: 1.5em;
          }
        }
      }
    }
  }
}
