nav {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(112, 160, 118, 0.8);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  display: none;

  &.nav--active {
    display: inherit;
    animation: fade-in 0.3s linear;
    animation-fill-mode: forwards;
  }

  a {
    color: #eee;
    font-size: 1.8em;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.3s;
    margin: 2em 0;

    &:hover {
      transform: scale(1.1);
    }

    &.lang {
      border: 1px solid white;
      padding: 0.5em;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  nav {
    display: inherit;
    opacity: 1;
    height: inherit;
    background-color: transparent;

    position: static;
    transform: translate(0, 0);

    flex-direction: row;
    justify-content: flex-end;

    a {
      margin-left: 4em;
      font-size: 1.2em;

      &:hover {
        color: #70a076;
        transform: none;
      }
    }
  }
}

@media only screen and (max-height: 400px) {
  nav {
    a {
      font-size: 1.2em;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
