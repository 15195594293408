.language-button {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #70a076;
  font-size: 1.2em;
  padding: 0.8em 1em 0.6em 1em;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px 0 0 0;

  &:hover {
    background-color: darken(#70a076, 10%);
  }
}

@media only screen and (min-width: 800px) {
  .language-button {
    position: fixed;
    top: 50%;
    bottom: auto;
    writing-mode: vertical-rl;
    text-orientation: upright;
    padding: 1em 0.5em;
    border-radius: 10px 0 0 10px;
    transform: translateY(-50%);
  }
}
