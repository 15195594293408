.slider {
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .overlay {
    position: absolute;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
  }

  .overlay-full {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .message {
    width: 80%;
    min-height: 245px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    img {
      width: 220px;
      height: auto;

      opacity: 0;
      animation-name: fade-down;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      animation-delay: 3.6s;

      &:first-of-type {
        animation-name: fade-up;
      }

      &:last-of-type {
        animation-name: fade-down;
        transform: rotate(180deg);
      }
    }

    .text {
      color: #eee;
      font-family: "Playfair Display";

      opacity: 0;
      animation-name: fade-down;
      animation-duration: 0.8s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;

      &.text--sub {
        font-size: 2em;
        font-weight: 400;
        font-style: italic;
        letter-spacing: 1px;
        margin: 1.2em 0 0.5em 0;
        animation-delay: 2.8s;
      }

      &.text--main {
        font-size: 5em;
        font-family: "Alex Brush", sans-serif;
        margin-bottom: 0.1em;
        animation-delay: 2s;
      }
    }
  }
}

@keyframes fade-down {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-up {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (min-width: 640px) {
  .slider {
    .message {
      .text {
        &.text--sub {
          font-size: 2.2em;
        }

        &.text--main {
          font-size: 8em;
        }
      }
    }
  }
}

@media only screen and (max-height: 500px) {
  .slider {
    .message {
      width: 80%;
      min-height: 180px;

      .text {
        &.text--sub {
          font-size: 1.5em;
        }

        &.text--main {
          font-size: 5em;
        }
      }
    }
  }
}
