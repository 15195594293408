.video {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 6em 0 5em;

  .video-responsive {
    position: relative;
    height: 0;
    padding-bottom: 55%;
    margin-bottom: 5em;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}
