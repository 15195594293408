.our-story {
  width: 100%;
  height: 60vh;
  min-height: 600px;
  background-image: url("../../images/story.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .our-story-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }

  .our-story-box {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 2em;

    .text-box {
      position: relative;
      width: 100%;
      max-width: 500px;
      padding: 40px 30px;
      background-color: #f7f7f7;
      text-align: center;

      .heading {
        font-family: "Alex Brush", sans-serif;
        font-size: 3em;
        margin-bottom: 0.5em;
        color: #1a1a1a;
      }

      p {
        color: #808080;
        font-size: 1.2em;
      }
    }
  }
}

@media only screen and (min-width: 640px) {
  .our-story {
    .our-story-box {
      padding-left: 10%;
      justify-content: left;

      .text-box {
        padding: 60px 50px;

        .heading {
          font-size: 4.5em;
        }

        p {
          font-size: 1.4em;
        }
      }
    }
  }
}
