.welcome {
  text-align: center;

  .welcome-heading {
    font-family: "Alex Brush", cursive;
    font-size: 3em;
    margin: 1em 0 0.5em 0;
  }

  .welcome-desc {
    width: 100%;
    max-width: 750px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #808080;
    padding: 0 20px;
    margin: auto;
    line-height: 24px;
    white-space: pre-line;
  }

  .img {
    max-width: 350px;
    width: 100%;
    height: auto;
    margin-top: -9em;
  }
}
@media only screen and (min-width: 640px) {
  .welcome {
    .welcome-heading {
      font-size: 5em;
    }

    .welcome-desc {
      font-size: 15px;
    }
  }
}
