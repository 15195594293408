.gallery {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 6em 0 0;

  .flower-line {
    width: 100%;
    max-width: 700px;
    padding: 8em 1em 0 1em;
  }

  .gallery-heading {
    font-family: 'Alex Brush', sans-serif;
    font-size: 3em;
    margin: 2em 0 0.5em 0;
    text-align: center;
  }

  .masonry {
    div img {
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        filter: brightness(0.6);
      }
    }
  }

  .button-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 600px;
    margin: 2em auto 5em auto;

    button {
      margin-top: 4em;
      padding: 1em 2em;
      border: 1px solid #bebdbd;
      border-radius: 5px;
      flex: 1;

      font-size: 1em;
      font-weight: bold;
      text-transform: uppercase;

      cursor: pointer;
      transition: 0.3s;

      background-color: #70a076;
      color: #fff;

      &:hover {
        background-color: darken(#70a076, 15%);
      }
    }
  }
}

@media only screen and (min-width: 640px) {
  .gallery {
    .gallery-heading {
      font-size: 5em;
    }

    .flower-line {
      padding: 8em 3em 0 3em;
    }

    .button-group {
      flex-direction: row;
    }
  }
}
