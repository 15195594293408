.bride-groom {
  width: 100%;
  padding: 10em 1em 16em 1em;
  text-align: center;

  .name {
    margin: 3em 5em;

    div {
      font-family: "Alex Brush", sans-serif;
      font-size: 3em;
      text-align: center;
    }
  }

  img {
    width: 100%;
    max-width: 250px;
    min-width: 200px;
    height: auto;
    border-radius: 100%;
  }
}

@media only screen and (min-width: 640px) {
  .bride-groom {
    display: flex;
    align-items: center;
    justify-content: center;

    .name {
      margin: 3em 8em;

      div {
        font-size: 4em;
      }
    }
  }
}
