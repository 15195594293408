footer {
  background-color: #fafafa;
  text-align: center;
  padding: 6em;
  width: 100%;

  .logo {
    font-size: 4em;
    font-family: "Alex Brush", cursive !important;
    margin-bottom: 0.5em;
  }

  .copyright {
    font-size: 1.4em;
    color: #5a5a5a;
  }
}
